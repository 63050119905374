<template>
    <div>
        <button @click="generateSitemap">Gerar Sitemap</button>
        <a :href="sitemapUrl" download="sitemap.xml" v-if="sitemapUrl">Download Sitemap</a>
    </div>
</template>

<script>
import paginas from '@/assets/pages.json'

export default {
  data () {
    return {
      sitemapUrl: ''
    }
  },
  methods: {
    generateSitemap () {
      const urls = paginas.pages.map(page => `
          <url>
            <loc>${page.meta.canonical}</loc>
            <lastmod>${page.dateModified}</lastmod>
            <changefreq>daily</changefreq>
            <priority>0.8</priority>
          </url>
        `).join('')

      const sitemapXml = `
          <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
            ${urls}
          </urlset>
        `.trim()
      const blob = new Blob([sitemapXml], { type: 'application/xml' })
      this.sitemapUrl = URL.createObjectURL(blob)
    }
  }
}
</script>
